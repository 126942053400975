







































































































import { Component, Vue } from 'vue-property-decorator';
import AuthAlert from '@/shared/components/auth/AuthAlert.vue';
import { UserModule } from '../../store';
import { Role } from '../../utils/permissions/permission.model';
import { UserCreate } from '../../store/modules/user/user.model';
import { DivisionModule } from '@/store/index';
import { RolesService } from '@/shared/services/roles/roles.service';

@Component({
  components: {
    AuthAlert
  }
})
export default class CreateUser extends Vue {
  divisionModule = DivisionModule;
  userModule = UserModule;

  d = '0000';
  t = null;

  loading = false;
  roleOptions = [];

  dismissCountDown = 0;

  async mounted() {
    const data = await RolesService.getRolesTableData();
    if (data) {
      this.roleOptions.push(
        ...data.filter(({role}) => !['admin', 'driver'].includes(role.toLocaleLowerCase())).map(i => ({ value: i.role, text: i.role }))
      );
    }
  }

  form: UserCreate = { email: '', name: '', role: null, phone: '', site: 'UA' };

  async createUser() {
    this.loading = true;
    try {
      await this.userModule.createUser(this.form);
      this.dismissCountDown = 8;
    } catch (e) {
      this.dismissCountDown = 0;
      console.log(e);
    }
    this.loading = false;
  }

  countDownChanged(dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown;
  }

  resetForm() {
    this.form = { email: '', name: '', role: null, phone: '', site: 'UA' };
  }

  change(e) {
    console.log(e);
  }
}
